import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';

import G from './G';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    setFetchAttempted(true); // Set to true as fetch begins
  
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
  
        if (decodedCity === "unknown") {
          // If city is "unknown", fetch from the external API
          fetch('https://freeipapi.com/api/json/')
            .then(response => response.json())
            .then(externalData => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData); // Log the external API data
            })
            .catch(error => {
              console.error('Fetch error on external API:', error);
            });
        } else {
          // Use the local API data
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase())); // Assumes regionNames is defined and imported
          setCity(decodedCity);
          console.log(data); // Log the local API data
        }
      })
      .catch(error => {
        console.error('Fetch error on local API:', error);
        // Optionally handle the error by fetching from external API or other means
      });
  
  }, []);



  const imageUrls = [
    'https://i.ibb.co/dJtgRCn/cropped-Screenshot-2024-06-13-at-07-15-10.png',
    'https://i.ibb.co/vskGsnf/cropped-20240523-210731671-i-OS.jpg',
    'https://i.ibb.co/GJq8v6m/cropped-20240523-002913000-i-OS.jpg',
    'https://i.ibb.co/xGVPxCv/cropped-20240527-183832588-i-OS.jpg',
    'https://i.ibb.co/h1G6xNY/cropped-20240527-185214108-i-OS.jpg',
    'https://i.ibb.co/HGmRkTr/cropped-20240528-131729923-i-OS.jpg',
    'https://i.ibb.co/dJtgRCn/cropped-Screenshot-2024-06-13-at-07-15-10.png',
    'https://i.ibb.co/vskGsnf/cropped-20240523-210731671-i-OS.jpg',
    'https://i.ibb.co/GJq8v6m/cropped-20240523-002913000-i-OS.jpg',
    'https://i.ibb.co/xGVPxCv/cropped-20240527-183832588-i-OS.jpg',
    'https://i.ibb.co/h1G6xNY/cropped-20240527-185214108-i-OS.jpg',
    'https://i.ibb.co/HGmRkTr/cropped-20240528-131729923-i-OS.jpg'
  ];
  return (
    <Router>
    <Routes>
      <Route path="/" element={
   <div className="container" >
   <Swiper
     effect={'coverflow'}
     grabCursor={true}
     centeredSlides={true}
     loop={true}
     slidesPerView={'2'}
     coverflowEffect={{
     rotate: 15,
     stretch: 0,
     depth: 100,
     modifier: 1,
     slideShadows: true,
    }}
   autoplay={{ // Corrected from autoPlay to autoplay
     delay: 2000,
     disableOnInteraction: false,
   }}
   breakpoints={{
     // Screens smaller than 640px
     640: {
       slidesPerView: 2,
     },
     // Larger screens
     640: {
       slidesPerView: 3, // or whatever number you prefer
     },
   }}
   modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
   className="swiper-container"
 >
   {imageUrls.map(url => (
     <SwiperSlide key={url} className="swiper-slide">
       <img src={url} alt="slide_image"/>
     </SwiperSlide>
   ))}
     <div className="slider-controler">
       <div className="swiper-pagination"></div>
     </div>
   </Swiper>

         <div className='textContainer' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
       <p style={{ fontSize: '28px', margin: '0 0 10px 0' }}> 
           <strong>🎁85% OFF SALE🎁 👻</strong>
       </p>
       <div className="detailsText">
           🍑 Lara Hill <br/>
           ❤️ 19 years<br/>
           🟢 Online Now<br/>
           📍 {city ? `${city} ${region}` : 'Your City'}<br/><br/>

           I personally respond to messages on here   <br/><br/>

           Message me to create a real connection, let’s see where things go ❤️
       </div>
   </div>



     <a href="https://onlyfans.com/larahillx/c1" id="customButton" >
       Send me a message
     
     </a>

     <p className="exclusiveContent">
      <strong>Let's have fun 😉 Only $3.00 today 🔥</strong>
     </p>
     <Analytics/>
   </div>

} />
<Route path="/I-Am-Waiting-For-You/onlyfans.com/larahillx" element={<G />} />
<Route path="/onlyfans.com/larahillx" element={<G />} />
</Routes>
</Router>
  );
}

export default App;
